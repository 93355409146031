import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import EditorJS from '@editorjs/editorjs';
const Header = require('@editorjs/header');
import { BlogServiceService } from '../blog-service.service';
import ImageTool from '@editorjs/image';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import * as AWS from 'aws-sdk';
const Delimiter = require('@editorjs/delimiter');
const InlineCode = require('@editorjs/inline-code');
const ColorPlugin = require('editorjs-text-color-plugin');
import { OutputData } from '@editorjs/editorjs';
import { environment } from 'src/environments/environment';
import { MetaTagsPopupComponent } from '../meta-tags-popup/meta-tags-popup.component';
import { Blog, BlogStatus, BlogType, Blog_Cachekeys } from '../blog';
import moment from 'moment';
import { DeleteBlogComponent } from '../delete-blog/delete-blog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationFilterComponent } from '../confirmation-filter/confirmation-filter.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PricingDetailsComponent } from 'src/app/shared-module-files/components/pricing-details/pricing-details.component';

@Component({
  selector: 'app-create-blogs',
  templateUrl: './create-blogs.component.html',
  styleUrls: ['./create-blogs.component.scss', './mq-create-blogs.component.scss']
})
export class CreateBlogsComponent {

  action: String = "";
  homeDirection: boolean = false;
  constructor(
    private blogService: BlogServiceService,

    private router: Router,
    private route: ActivatedRoute,
    private _snackbar : MatSnackBar,
    private _bottomSheet:MatBottomSheet,
  ) {
    this.route.queryParams.subscribe(queryParams => {
      this.action = queryParams['action'];
      if (this.action != null) {
        if (this.action === 'addBlogHome') {
          this.homeDirection = true;
        }
      }
    })
    this.getScreenSize();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  words: any;
  readTime: any = 0;
  wordCount: any = 0;
  characterCount:any = 0;
  paragraphCount: any = 0;
  sentenceCount: any = 0;
  ngOnInit(): void {
    this.blog = this.blogService.getBlogData();

    if (!this.blog.businessId && this.blog.blogType === BlogType.BUSINESS) {
      let businessDetails: any = window.localStorage.getItem('bDetails');
      businessDetails = businessDetails ? JSON.parse(businessDetails) : null;

      if (businessDetails) {
        this.blog.businessId = businessDetails.id;
      }
    }

    this.blog.blogType = BlogType.BUSINESS;
    // this.intialzedEditor();
  }

  ngAfterViewInit(): void {
    this.showApiData();
  }

  screenLoading: boolean = false;

  @ViewChild('editor', { read: ElementRef, static: true }) editorElement:
    | ElementRef
    | undefined;

  previousData: OutputData | undefined;
  @ViewChild("text") text: ElementRef | undefined;
  editor: EditorJS | undefined;

  private intialzedEditor(): void {
    this.editor = new EditorJS({
      // minHeight:200,
      holder: this.editorElement?.nativeElement,
      tools: {
        delimiter: Delimiter,
        header: {
          class: Header,
          shortcut: 'CMD+SHIFT+H',
          inlineToolbar: ['link', 'bold', 'italic'],
        },

        Color: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            colorCollections: [
              '#EC7878',
              '#9C27B0',
              '#673AB7',
              '#3F51B5',
              '#0070FF',
              '#03A9F4',
              '#00BCD4',
              '#4CAF50',
              '#8BC34A',
              '#CDDC39',
              '#FFF',
            ],
            defaultColor: '#FF1300',
            type: 'text',
          },
        },
        Marker: {
          class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
          config: {
            defaultColor: '#FFBF00',
            type: 'marker',
          },
        },
        // Marker :{
        //   class : Marker,
        //   shortcut : 'CMD+SHIFT+M'
        // },
        list: {
          class: List,
          inlineToolbar: ['link', 'bold', 'italic'],
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        image: {
          class: ImageTool,
          config: {
            data: {
              withBorder: false,
              stretched: false,
              withBackground: false,
            },
            uploader: {
              /**
               * Upload file to the server and return an uploaded image data
               * @param {File} file - file selected from the device or pasted by drag-n-drop
               * @return {Promise.<{success, file: {url}}>}
               */
              uploadByFile(file: any) {
                // your own uploading logic here
                const AWSService = AWS;
                const imageEnvCognito =
                  environment.componentImageUploading.CredentialsProvider.CognitoIdentity.Default;
                const imageEnvUtility =
                  environment.componentImageUploading.S3TransferUtility.Default;
                const region = imageEnvUtility.Region;
                const bucketName = imageEnvUtility.Bucket;
                const IdentityPoolId = imageEnvCognito.PoolId;
                // ? Configures the AWS service and initial authorization
                AWSService.config.update({
                  region: region,
                  credentials: new AWSService.CognitoIdentityCredentials({
                    IdentityPoolId: IdentityPoolId,
                  }),
                });
                const s3 = new AWSService.S3({
                  apiVersion: '2012-07-10',
                  params: {
                    Bucket: bucketName,
                  },
                });

                // const element = e.srcElement.files[0];
                // var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
                let imgPromise = new Promise((res, rej) => {
                  if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      s3.upload(
                        {
                          Key:
                            'images/' +
                            Math.floor(100000 + Math.random() * 900000) +
                            'c' +
                            new Date().getTime() +
                            file.name,
                          Bucket: bucketName,
                          Body: file,
                          ACL: 'public-read',
                        },
                        // options,
                        function (err: any, data: any) {
                          if (err) {
                            rej(err);
                          } else {
                            res(data);
                          }
                        }
                      );
                    };
                  }
                });

                return imgPromise
                  .then((data: any) => {
                    // this.imageObject = data;
                    //console.log('awsResult', data);
                    return {
                      success: 1,
                      file: {
                        url: data.Location,
                        height: '500px',
                        // any other image data you want to store, such as width, height, color, extension, etc
                      },
                    };
                  })
                  .catch((err) => {
                    //console.log(err);
                    return err;
                  });
              },
            },
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
      },
      data: this.previousData,
      defaultBlock: 'header',
      autofocus: true,
      placeholder: 'Enter content',
      onChange: (api, event) => {
        console.log('Now I know that Editor\'s content changed!', event)
      }
    });
    this.editor?.blocks?.clear();
  }

  publishBlog() {
    this.editor?.save().then((data: any) => {
      if (data.blocks && !data.blocks.length) {
        this.blogService.openSnack(
          'Please write something to create Post',
          'Close'
        );
        this.screenLoading = false;
        return;
      } else if (this.blog.postTitle === '' || this.blog.postSummary === '') {
        this._snackbar.open(
          `Please fill mandatory fields`,
          'Ok',
          {
            duration: 1500
          }
        );

        this.blogService.setBlogData(this.blog);

        if (this.screenWidth > 475) {
          let blogModel = this.blogService.filterDialog(
            MetaTagsPopupComponent,
            'filter-dialog-container',
            'auto',
            '350px',
            'relative',
            {
              data: {
                typeRequest: "addtags"
              }
            },
          );
          blogModel.afterClosed().subscribe((data: any) => {
            if (!data) return;

            this.blog = this.blogService.getBlogData();
          });
        } else {
          
          this._bottomSheet.open(MetaTagsPopupComponent, {
            data: {
              typeRequest: "addtags"
            },
            panelClass: 'bottom-sheet-container'
          }).afterDismissed().subscribe((data: any) => {
            if (!data) return;

            this.blog = this.blogService.getBlogData();
          });
        }
        return;
      }


        this.blog.blocks = data.blocks;

          this.getBlocksTextLen(data.blocks);
        this.blog.createdBy = window.localStorage.getItem('fname') || '',
        this.blog.readTime = Math.ceil(this.wordCount / 225).toString();
        this.blog.wordCount = this.wordCount;
        this.blog.blogStatus = BlogStatus.PUBLISH;

        this.blog.blogScheduledDate = moment(new Date()).format('YYYY-MM-DD');

        const date = new Date();
        this.blog.blogScheduleTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}.000`
        this.blog.updatedTimeStamp = moment(new Date()).format();

        if (this.blog.id) {
          this.updateBlog();
        } else {
          this.blog.createdTimeStamp = moment(new Date()).format();
          this.createBlog();
        }
      })
      .catch((err) => {
        this.screenLoading = false;
        //console.log(err);
      });
  }

  couldBeCounted(block) {
    return 'text' in block.data // it depends on tools you use
  }

  sumWords: any;
  getBlocksTextLen(blocks) {
    return blocks
      .filter(this.couldBeCounted)
      .reduce((sum, block) => {
        let data = block.data.text
        data = data.replaceAll("<br>", "");
        data = data.replaceAll("<b>", "");
        data = data.replaceAll("</b>", "");

        this.sumWords = this.sumWords + data.split(" ").length;
        return sum + data.split(" ").length;
      }, 0)
  }



  jsonData: any = '';
  showApiData() {
    console.log(this.blog)
    if (this.blog.id) {
      let dataSending: any = {
        time: 0,
        version: '',
        blocks: [],
      };
      dataSending.time = this.blogService.getBlogData().time;
      dataSending.version = this.blogService.getBlogData().version;
      dataSending.blocks = this.blogService.getBlogData().blocks;
      this.previousData = dataSending;
      this.getBlogDetailsWordsCount(this.previousData);
    } else {
      this.previousData = undefined;
    }
    this.intialzedEditor();
  }

  back() {
    window.localStorage.removeItem(Blog_Cachekeys.BLOG_SETTINGS);
    if (this.homeDirection) {
      this.router.navigate(['/admin/home']);
    }
    else {
      this.router.navigate(['/admin/website/blogs/listing']);
    }
  }

  saveToDraft() {
    this.screenLoading = true;
    // if(this.blog.postTitle === '' || this.blog.postSummary === ''){

    // }
    // else{
      this.editor
      ?.save()
      .then((data: any) => {
        if (data.blocks && !data.blocks.length) {
          this.blogService.openSnack(
            'Please write something to create Post',
            'Close'
          );
          this.screenLoading = false;
          return;
        }
        else if(this.blog.postTitle === '' || this.blog.postSummary === ''){
          this._snackbar.open(
            `Please fill mandatory fields`,
            'Ok',
            {
              duration:1500
            }
          );

          this.blogService.setBlogData(this.blog);

          // let blogModel = this.blogService.filterDialog(
          //   MetaTagsPopupComponent,
          //   'filter-dialog-container',
          //   'auto',
          //   '350px',
          //   'relative',
          //   {
          //     data: {
          //       typeRequest: "addtags"
          //     }
          //   },
          // );
          // blogModel.afterClosed().subscribe((data: any) => {
          //   if (!data) return;

          //   this.blog = this.blogService.getBlogData();
          // });
          if (this.screenWidth > 475){
            let blogModel = this.blogService.filterDialog(
              MetaTagsPopupComponent,
              'filter-dialog-container',
              'auto',
              '350px',
              'relative',
              {
                data: {
                  typeRequest: "addtags"
                }
              },
            );
            blogModel.afterClosed().subscribe((data: any) => {
              if (!data) return;
        
              this.blog = this.blogService.getBlogData();
            });
            }
        
            if (this.screenWidth < 475){
            let blogModel = this.blogService.openBottomSheet(
              MetaTagsPopupComponent,
              'filter-dialog-container',
              {
                data: {
                  typeRequest: "addtags"
                }
              },
            );
            blogModel.afterDismissed().subscribe((data: any) => {
              if (!data) return;
        
              this.blog = this.blogService.getBlogData();
            });
            }
          return;

          
        }

        this.blog.blocks = data.blocks;

        this.getBlocksTextLen(data.blocks);

        this.blog.createdBy = window.localStorage.getItem('fname') || '',
        this.blog.readTime = Math.ceil(this.wordCount / 225).toString();
        this.blog.wordCount = this.wordCount;
        this.blog.blogStatus = BlogStatus.SAVE;
        this.blog.updatedTimeStamp = moment(new Date()).format();

        if (this.blog.id) {
          this.updateBlog();
        } else {
          this.blog.createdTimeStamp = moment(new Date()).format();
          this.createBlog();
        }
      })
      .catch((err) => {
        this.screenLoading = false;
      });
    // }
  }

  private createBlog() {
    const blogData = JSON.parse(JSON.stringify(this.blog));
    delete blogData.id;

    this.blogService.createBlog(blogData).subscribe({
      next: (res: any) => {
        this.showSuccessMessage();

        this.screenLoading = false;
        this.back();
      },
      error: (err: any) => {
        if(err.error.errorCode === 2000){
          this.blogService.openSocialBrand(PricingDetailsComponent, '100vh', '100%', {})
        }
        this.sumWords = 0;
        this.blog.readTime = '0';
        this.screenLoading = false;

        this.blogService.openSnack(
          'Something went wrong, please try again later',
          'Ok'
        );
      },
    });
  }

  private updateBlog() {
    this.blogService.updateBlog(this.blog).subscribe({
      next: (res: any) => {
        this.showSuccessMessage();
        this.screenLoading = false;
        this.back();
      },
      error: (err: any) => {
        this.sumWords = 0;
        this.blog.readTime = '0';
        this.screenLoading = false;

        this.blogService.openSnack(
          'Something went wrong, please try again later',
          'Ok'
        );
      },
    });
  }

  showSuccessMessage() {
    if (this.blog.blogStatus === BlogStatus.SAVE) {
      this.blogService.openSnack(
        'Your post is successfully saved and you can edit later.',
        'Ok'
      );
    } else {
      if(this.blog.blogStatus === BlogStatus.PUBLISH)
      this.blogService.openSnack(
        'Your post will be published. Thankyou',
        'Ok'
      );
    }
  }

  deleteBlog() {
    if(this.screenWidth > 475){
    this.blogService.filterDialog(
      DeleteBlogComponent,
      'filter-dialog-container',
      'auto',
      '350px',
      'relative',
      { blogId: this.blog.id },
    );
    }
    if(this.screenWidth < 475){
      this.blogService.openBottomSheet(
        DeleteBlogComponent,
        'filter-dialog-container',
        { blogId: this.blog.id },
      );
    }
  }
  confirmationFilter() {
    if (this.screenWidth > 475){
    this.blogService.filterDialog(
      ConfirmationFilterComponent,
      'filter-dialog-container',
      'auto',
      'auto',
      'relative',
      { blogId: this.blog.id },
    );
    }

    if (this.screenWidth < 475){
      this.blogService.openBottomSheet(
        ConfirmationFilterComponent,
        'filter-dialog-container',
        { blogId: this.blog.id },
      );
    }
  }

  countwords() {
    //console.log('words');
  }

  // words: any;
  // wordCount: number = 0;
  // characterCount: number = 0;
  // paragraphCount: number = 0;
  // sentenceCount: number = 0;
  async wordCounter(event) {

    const savedData = await this.editor?.save();
    const content = savedData?.blocks.map(block => block.data.text).join(" ");

    this.characterCount = content ? content.length : 0;
    this.wordCount = content ? content.split(/\s+/).filter(word => word !== '').length : 0;
    this.paragraphCount = content ? content.split(/\n\s+\n/).filter(paragraph => paragraph.trim() !== '').length : 0;
    this.sentenceCount = content ? content.split(/[.!?]/).filter(sentence => sentence.trim() !== '').length : 0;

    //for reading time
    const wordsPerMinute = 225;
    const wordCount = content ? content.split(/\s+/).filter(word => word !== '').length : 0;
    const readTimeInminutes = wordCount / wordsPerMinute;
    const minutes = Math.round(readTimeInminutes);
    const minutesText = minutes === 1 ? "minute" : "minutes";
    const seconds = Math.round(readTimeInminutes * 60) % 60;
    const secondsText = seconds === 0 ? "" : seconds + " seconds";

    this.readTime = minutes + " " + minutesText + " " + secondsText;
  }

  async getBlogDetailsWordsCount(savedData){
    const content =  await savedData?.blocks.map(block => block.data.text).join(" ");

    this.characterCount = content ? content.length : 0;
    this.wordCount = content ? content.split(/\s+/).filter(word => word !== '').length : 0;
    this.paragraphCount = content ? content.split(/\n\s+\n/).filter(paragraph => paragraph.trim() !== '').length : 0;
    this.sentenceCount = content ? content.split(/[.!?]/).filter(sentence => sentence.trim() !== '').length : 0;

    //for reading time
    const wordsPerMinute = 200;
    const wordCount = content ? content.split(/\s+/).filter(word => word !== '').length : 0;
    const readTimeInminutes = wordCount / wordsPerMinute;
    const minutes = Math.round(readTimeInminutes);
    const minutesText = minutes === 1 ? "minute" : "minutes";
    const seconds = Math.round(readTimeInminutes * 60) % 60;
    const secondsText = seconds === 0 ? "" : seconds + " seconds";

    this.readTime = minutes + " " + minutesText + " " + secondsText;
  }

  blog: Blog = new Blog();

  openBlogSettingsDialog(ev: MouseEvent) {
    ev.stopPropagation();

    if (this.screenWidth > 475){
    this.blogService.setBlogData(this.blog);

    let blogModel = this.blogService.filterDialog(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      'auto',
      '350px',
      'relative',
      {
        data: {
          typeRequest: "addtags"
        }
      },
    );
    blogModel.afterClosed().subscribe((data: any) => {
      if (!data) return;

      this.blog = this.blogService.getBlogData();
    });
    }

    if (this.screenWidth < 475){
      this.blogService.setBlogData(this.blog);

    let blogModel = this.blogService.openBottomSheet(
      MetaTagsPopupComponent,
      'filter-dialog-container',
      {
        data: {
          typeRequest: "addtags"
        }
      },
    );
    blogModel.afterDismissed().subscribe((data: any) => {
      if (!data) return;

      this.blog = this.blogService.getBlogData();
    });
    }
  }
}
