<div class="header">
    <div class="header-text">+ Add field group</div>
    <div class="close-direction-btns">
      <div class="close-btn" (click)="dismiss()">
        Done
      </div>
    </div>
  </div>

  <div class="label">
    <label class="input-label">Field Group Name</label>
    <input class="input-text" placeholder="Name" type="text"  required [(ngModel)]="fieldGroupDetails.fieldGroupName" />
    <div  class="error">Field Group Name is required</div>
  </div>

  <div class="button">
    <button (click)="createFieldgroup()" >Save</button>
  </div>