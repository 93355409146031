<section>
    <div class="header d-flex ">
        <div class="delete-section">
            <img src="../../../../../../assets/images/invoice/delete-invoice.svg" alt="" class="delete-img">
        </div>
        <div class="delete-text">{{data.heading}}</div>
    </div>
    <div class="delete-text">{{data.heading | titlecase}}</div>

    <div class="subtext">{{data.subText}}</div>
    
    <div class="footer-section">
        <div class="cancle-section" (click)="cancel()">
            <button class="cancle">{{data.secondaryButton}}</button>
        </div>
        <div class="createCont-section" (click)="deleteoption()">
            <button class="create-contact">{{data.primaryButton}}</button>
        </div>

    </div>
</section>
