import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pricing-details',
  templateUrl: './pricing-details.component.html',
  styleUrls: ['./pricing-details.component.scss'],
  standalone: true,
  imports: [MatIconModule,FormsModule, CommonModule]
})
export class PricingDetailsComponent
{
  constructor(private close : MatDialogRef<PricingDetailsComponent>,
    private router: Router,
    private SubscriptionService: SubscriptionService,
    private _paymentService : PaymentsService,
    private _snackbar : MatSnackBar,
    private _dbService : DbService,
    public dialogRef: MatDialogRef<PricingDetailsComponent>,
  )
  {

  }
  ngOnInit()
  {
    this.getAllPlans();
  }

  twoButtons = [
    {
      name:'monthly',
      status:false,
      value:'MONTHLY'
    },
    {
      name:'yearly',
      status: true,
      value:'ANNUALLY'
    }
  ];

  ngOnDestroy() {
    this.pricingSubscription?.unsubscribe();
  }

  closeDialog()
  {
    this.close.close();
  }
  subscriptionpoints : any = ["Extend your limit upto 50 new creations","You can create muliple Businesses","Enjoy without any limits"]
  features :  any = []

  navigateToUpgradePage(){
    this.close.close();
    this.router.navigate(['/admin/account/subscriptions'])
  }

  subscriptionType: 'MONTHLY' | 'ANNUALLY' = 'ANNUALLY';

  pricingSubscription?: Subscription;
  pricingData : any;
  getAllPricingDetails()
  {
    this.pricingSubscription = this.SubscriptionService.getNextplanFeatures().subscribe(
      (res : any)=>{
       this.pricingData = res.plan;
       this.features = this.pricingData.planFeaturesList?.split(",")
      }
    )
  }

  planSubscription?: Subscription;
  planList: any[] = []
  getAllPlans() {
    this.planSubscription = this.SubscriptionService.getSubscriptionDetails().subscribe((res: any) => {
      this.planList = res.data;
      this.planList?.forEach((plan: any) => {
        plan['subscription'] = false;
        plan['features'] = plan?.plan?.planFeaturesList?.split(",");
      })
      console.log(this.planList)
    })
  }


  upgradePlan(plan: any){

    let data : any  = {
      businessId : this._dbService.getBusinessId(),
      subscriptionId: plan.plan.id,
      isMonthly:this.subscriptionType === 'MONTHLY',
      domain:`${environment.redirectingUrl}`,
      affiliatePartnerKey : ""
    }
    if(localStorage.getItem("partnerId"))
      {
        data.affiliatePartnerKey = localStorage.getItem("partnerId")
      }

    this._paymentService.subscribePlan(data).subscribe(
      (res:any) => {
        window.location.href = `${res.data.url}`
        this._dbService.setPaymentId(res.data.paymentId);
        window.localStorage.setItem('endDate',res.data.endDate);
        window.localStorage.setItem('planName',res.data.planName);
        window.localStorage.setItem('planAmount',res.data.planAmount);

      },
      (err) => {
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        );
      }
    )
  }

  closeWindow() {
    this.dialogRef.close()
  }

  changeSubscriptionType(type) {
    this.subscriptionType = type.value
  }

  contactUs() {
    window.open('https://simpo.ai/contact-us', '_blank')
  }
}
