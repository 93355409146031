import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviderModel, DeviderType, svgData } from 'simpo-component-library';

@Component({
  selector: 'app-divider-list',
  templateUrl: './divider-list.component.html',
  styleUrls: ['./divider-list.component.scss']
})
export class DividerListComponent {
  constructor(public dialogRef: MatDialogRef<DividerListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {}

  Object = Object
  dividerList = svgData;

  dividerData?: DeviderModel;
  ngOnInit() {
    this.dividerData = this.dialogData.data
    console.log(this.dividerList)
  }

  closeDialog() {
    this.dialogRef.close();
  }
  current : any ;
  changeDivider(svgData: any) {
    if(this,this.dividerData)
      this.dividerData.deviderType = svgData;
      this.current = svgData
  }
}
