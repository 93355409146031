import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {

  constructor(
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private http: HttpClient,
    private snackbar: MatSnackBar,
  ) { }




  openDialog(componentName: any, height: any, width: any, maxWidth: any, dataModel: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth: maxWidth,
      data: dataModel,
      disableClose: true,
      position: { right: '0px', top: '0px' },
    });
  }

  openSocialBrand(componentName: any, height: any, width: any, data: any, maxWidth: string = '100vw', maxHeight: string = '100vh') {
    return this.dialog.open(componentName, {
      height: height,
      width: width,
      data: data,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    });
  }

  openBottomSheet(componentName: any, data: any) {
    return this._bottomSheet.open(componentName, {
      data: data,

    });
  }

  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  getProductList(data:any){
    if(!data.request)
      data.request = 'BUSINESS';

    return this.http.put(
      environment.baseUrl + `ecommerce/product/item/search`,data
    )
  }

  searchProductItems(data:any){
    if(!data.request)
      data.request = 'BUSINESS';

    return this.http.put(
      environment.baseUrl + `ecommerce/product/item/search`,data
    )
  }

  deleteProduct(productId:any){
    return this.http.delete(
      environment.baseUrl + `ecommerce/product/variant?itemId=${productId}`
    )
  }

  searchCategories(data:any){
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/get/category`, data
    )
  }
  searchCollection(data:any)
  {
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/get/collection`, data
    )
  }
  createCategories(data:any){
    return this.http.post(
      environment.baseUrl + `ecommerce/inventory/item/category`,data
    )
  }
  getcategoriesList(data:any){
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/get/category`, data
    )
  }
  deleteCategories(categoryId:any){
    return this.http.delete(
      environment.baseUrl + `ecommerce/inventory/category?categoryId=${categoryId}`
    )
  }
  getitemsListByCategories(data:any){
    if(!data.request)
      data.request = 'BUSINESS';

    return this.http.put(
      environment.baseUrl + `ecommerce/product/item/search`,data
    )
  }

  getCategoryListByCollectionId(collectioId: string) {
    return this.http.get(
      environment.baseUrl + `ecommerce/inventory/category/collectionId?collectionId=${collectioId}`
    )
  }

  getCollectionList(data: any){
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/get/collection`, data
    )
  }
  createCollection(data: any){
    return this.http.post(
      environment.baseUrl + `ecommerce/inventory/item/collection`, data
    )
  }
  deleteCollection(collectionId: any){
    return this.http.delete(
      environment.baseUrl + `ecommerce/inventory/collection?collectionId=${collectionId}`
    )
  }

  exportItem(businessId: any) {
    return this.http.get(
      environment.baseUrl + `ecommerce/product/export?businessId=${businessId}`, { responseType: 'blob' }
    )
  }

  downloadSampleExcel(subIndustryId: any) {
    return this.http.get(
      environment.serverIp + `ecommerce/export?subIndustryId=${subIndustryId}`, { responseType: 'blob' }
    )
  }

  importItem(businessId: any, businessName: any ,formData: FormData) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type');
    return this.http.post(
      environment.baseUrl + `ecommerce/product/import?businessId=${businessId}&businessName=${businessName}`, formData, { headers: headers }
    )
  }

  // Rating & Review APIs (need business Id)
  getAllReviewText(): Observable<unknown> {
    return this.http.get(environment.baseUrl + 'ecommerce/user/all');
  }
}
