<section class="main-section">
    <div class="main-container">
        <div class="bussiness-name">
            {{businessName}}

        </div>
        <div class="card-section">
            <div class="card-container">
                <div class="invoice-name">
                    Hi, {{invoiceData?.customerDetails?.name}}!
                </div>
                <div class="invoice-business">
                    Invoice from {{businessName}}
                </div>
                <div class="invoice-status-container">
                    <div class="invoice-status">
                        <img src="../../../assets/images/invoice/waring-message.svg" alt="" class="invoice-img" *ngIf="invoiceData?.status === 'PARTIALLY_PAID' || invoiceData?.status === 'PENDING' || invoiceData?.status === 'CANCELLED'">
                        <img src="../../../assets/images//invoice/success-recepit.svg" alt="" class="invoice-img" *ngIf="invoiceData?.status === 'PAID'">
                        <div  [ngClass]="{'status-name' : invoiceData?.status === 'PARTIALLY_PAID',
                                         'pending' : invoiceData?.status === 'PENDING',
                                        'cancle' : invoiceData?.status === 'CANCELLED',
                                        'paid': invoiceData?.status === 'PAID'}" >
                            {{invoiceData?.status.replace('_', ' ') | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="total-amount-section">
                    <div class="total-amount">
                        Total Amount
                    </div>
                    <div class="total-amount-price">
                        ₹ {{invoiceData?.totalAmount}}
                    </div>
                </div>
                <div class="amount-section">
                    <div class="paid">
                        <div class="amount_paid">
                            PAID
                        </div>
                        <div class="paid-amount">
                            ₹ {{invoiceData?.totalPaidAmount}}
                        </div>
                    </div>
                    <div class="pending">
                        <div class="amount-pending">
                            PENDING
                        </div>
                        <div class="pending-amount">
                            ₹ {{invoiceData?.totalPayableAmount}}
                        </div>
                    </div>
                </div>
                <div class="due-section">
                    <div class="due-container">
                        Due on
                    </div>
                    <div class="due-date">
                        {{invoiceData?.dueDate |  date:'mediumDate' }}
                    </div>
                </div>
                <div class="invoice-no-section">
                    <div class="invoice-no">
                        <div class="invoice-no-heading">
                            {{invoiceService.removeUnderscore(type) | titlecase}}#
                        </div>
                        <div class="invoice-no-text">
                            {{invoiceData?.prefix}}-{{invoiceData?.invoiceDisplayCode}}
                        </div>
                    </div>
                    <div class="inoice-date">
                        <div class="invoice-no-date">
                            {{invoiceService.removeUnderscore(type) | titlecase}} Date
                        </div>
                        <div class="invoice-nodate">
                            {{invoiceData?.createdTimeStamp |  date:'mediumDate' }}
                        </div>
                    </div>
                </div>
                <div class="download-section" (click)="downloadPDF()">
                    <div class="download-container">
                        Download {{invoiceService.removeUnderscore(type) | titlecase}}
                    </div>
                </div>
            </div>

        </div>

    </div>

</section>
