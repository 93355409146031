import { Component, Input, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { ContactUsField, ContactUsModel } from 'simpo-component-library/lib/sections/contact-us/contact-us.modal';
import { TemplateService } from 'src/app/services/template.service';
import { AddContactFieldsComponent } from '../add-contact-fields/add-contact-fields.component';
import {CdkDragDrop, moveItemInArray, CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import { CrmServiceService } from 'src/app/master-config-components/micro-apps/crm/service/crm-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
@Component({
  selector: 'app-contact-form-editor',
  templateUrl: './contact-form-editor.component.html',
  styleUrls: ['./contact-form-editor.component.scss']
})
export class ContactFormEditorComponent {

  @Input() contactField: ContactUsField = {
    customLabel: false,
    successMessage: '',
    fields: [],
    button: 'send'
  };

  constructor(private dialogService: TemplateService,
    private crmService: CrmServiceService,
    private dbService: DbService
  ){}

  ngOnInit() {
   
    this.screenSize();
    this.getFieldsApi();
  }
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video'],
        [{ 'color': ['#E04181', '#11668C','color-picker'] }]
      ],

    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    }
  };

  screenWidth: any;
  @HostListener('window: resize',['$event'])
  screenSize(){
    this.screenWidth= window.innerWidth
  }

  contactUs = [
    {
        "componentId": "ed8f48e5-2552-42a0-8d06-4ae5fdc990fb",
        "id": "1ef05ee8-d5ea-6ea8-86f3-5323470130c6",
        "sectionType": "contact us",
        "sectionName": "Contact form",
        "desc": "Add a contact form",
        "type": null,
        "content": {
            "inputText": [
                {
                    "isRTE": false,
                    "label": "Heading",
                    "value": ""
                },
                {
                    "label": "Heading",
                    "value": "<p>Get In Touch</p><h5>If you have any questions or inquiries, please feel free to reach out to us. We strive to provide the best customer service and assistance to all our clients.</h5>",
                    "isRTE": true
                }
            ],
            "contactField": {
                "customLabel": true,
                "fields": [
                    {
                        "label": "Name",
                        "value": "Name",
                        "type": "text",
                        "required": true
                    },
                    {
                        "label": "Email",
                        "value": "Email",
                        "type": "email",
                        "required": true
                    },
                    {
                        "label": "Message",
                        "value": "Contact Us",
                        "type": "desc",
                        "required": true
                    }
                ],
                "button": "Send",
                "successMessage": "Message Sent"
            },
            "contentId": "8851dddd-62b3-45b8-b184-77f91807cee6",
            "image": {
                "altText": "some thing",
                "showImage": false,
                "id": "img1",
                "position": {
                    "x": 50,
                    "y": 50
                },
                "url": "https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/225541c1718723844893GY1kyj9bbSRjMe1a4VgCHmjPck.avif"
            }
        },
        "styles": {
            "layout": {
                "fit": "content",
                "spacing": {
                    "top": "small",
                    "bottom": "small"
                }
            },
            "positionLayout": {
                "label": "Form alignment",
                "value": "left",
                "types": [
                    "left",
                    "right"
                ]
            },
            "background": {
                "color": "#AABEC1",
                "accentColor": "#366B72",
                "image": "https://images.pexels.com/photos/255379/pexels-photo-255379.jpeg?cs=srgb&dl=pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-255379.jpg&fm=jpg",
                "opacity": 10,
                "showImage": false,
                "altName": "Background",
                "position": {
                    "x": 50,
                    "y": 50
                }
            },
            "animation": {
                "type": "none",
                "speed": "slow"
            },
            "border": {
                "display": false,
                "color": "#000000",
                "type": "DASHED",
                "thickness": "8"
            },
            "devider": {
                "display": false
            },
            "inputOpacity": 100
        },
        "action": {
            "buttons": [
                {
                    "styles": {
                        "type": "Outline",
                        "shape": "Round"
                    },
                    "id": "btn1"
                }
            ]
        }
    }
  ]

  manageFields() {    
    this.crmService.openServiceDialog(AddContactFieldsComponent,
      '100vh',
      '26%',
      '80vw',
      {data: this.contactUs[0].content.contactField.fields,screen: "SELECT_FIELDS"},

    )
}

pageNo: number = 0;
size: number = 10;
status = true;
showfields:any;
responseData: any = [];
dataSource: any = []



getFieldsApi() {

  this.crmService.getFieldsShowInForm(this.dbService.getBusinessId(),'ENQUIRY').subscribe(
    {
      next: (value: any) => {
        value.data.data.forEach(element => {
          if (element.showInForm && element.showInForm.includes("ENQUIRY")) {
            this.responseData.push(element);
            this.dataSource.push(element)
          }
        });
        // console.log(this.dataSource)
        // this.responseData = value.data.data;
        // this.dataSource = value.data.data;
      },
      error: (err: any) => {
        this.crmService.openSnack(err.error.message, "Close");
      },
    }
  )

}
 
  // manageFields() {
  //   if(this.screenWidth > 475){
  //     this.dialogService.openDialog(AddContactFieldsComponent, '', '30%', {data: this.contactField.fields})
  //   }
  //   if(this.screenWidth < 475){
  //     this.dialogService.openBottomSheet(AddContactFieldsComponent, {data: this.contactField.fields}, '')
  //   }
  // }
  
  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.contactField?.fields, event.previousIndex, event.currentIndex);
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
    this.contactField.fields = [...this.contactField.fields];
    this.dataSource = [...this.dataSource];
    // this.crmService.customFieldPositionLead.emit({positionChange:true})
  }

  sortPredicate(index: number, item: CdkDrag<number>) {
    return (index + 1) % 2 === item.data % 2;
  }
  canShowContactField(field) {
    return Object.hasOwn(field, "status") ? field.status : true;
  }
}
