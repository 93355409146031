<section class="main-section spinner" *ngIf="paymentStatus === 'ONGOING'">
  <div>
    <mat-spinner></mat-spinner>
    <p>Please Wait...</p>
  </div>
</section>
<!--
<section class="main-section spinner" *ngIf="paymentStatus === 'SUCCESS'">
  <div>
    <div class="image_section">
      <img src="./../../../../assets/images/common/96237-success.gif" alt="loader">
    </div>
    <div class="success_heading">
        Payment Successfull
    </div>
  </div>

</section> -->

<section class="main_section p-4 d-flex justify-content-center align-items-center flex-column" *ngIf="paymentStatus !== 'ONGOING'">
  <div class="success_failed_images" *ngIf="templateData[paymentStatus]?.attachmentUrl">
      <img [src]="templateData[paymentStatus]?.attachmentUrl" alt="image">
  </div>
  <div class="title mt-2">
      {{templateData[paymentStatus]?.name | titlecase}}
  </div>
  <div class="message mt-2 text-center">
      {{templateData[paymentStatus]?.message | titlecase}}
  </div>
</section>
<!-- <section class="main-section spinner" *ngIf="paymentStatus === 'CANCEL'">
  <div>
    <div class="image_section">
      <img src="./../../../../assets/images/common/paymentfailed.gif" alt="loader">
    </div>
    <div class="success_heading">
        Payment Failed
    </div>
  </div>
</section> -->
