<section class="main-section">
    <div class="header">
        <div class="header-text">Add Participants</div>
        <div class="close-image" (click)="cancel()">
            <img src="../../../../../../../../assets/images/close.svg" alt="">
        </div>
    </div>
    <hr class="section-divider">
    <div class="input-field-section">
        <div class="input-label">Participants<sup>*</sup></div>
        <div class="input-section">
            <mat-form-field class="select" appearance="outline">
              <mat-select placeholder="Select Participant Type" [(ngModel)]="participantType" (ngModelChange)="getParticpantList()">
                <mat-option *ngFor="let item of type" [value]="item.value">{{ item.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field class="add-email" appearance="outline">
              <input
                matInput
                placeholder="Search Participants"
                [(ngModel)]="searchText"
                (ngModelChange)="filterParticipants()"
                [matAutocomplete]="auto">
            </mat-form-field>
          
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredParticipants" [value]="option.leadName">
                {{ option.leadName | titlecase }}
              </mat-option>
            </mat-autocomplete>
          </div>
          
          <ng-container *ngFor="let element of mailList; let i = index">
            <div class="content-box" *ngIf="i < 3">
              <div class="checkbox">
                <mat-checkbox
                  color="primary"
                  (change)="addItem($event, element)"
                  [ngModel]="selectedParticpants.hasOwnProperty(element.leadId)">
                </mat-checkbox>
              </div>
              <div class="participant-info">
                <div class="name">{{ element?.leadName | titlecase }}</div>
                <div class="mail-details">
                  <div class="mail-item">
                    <img src="../../../../../../../../assets/images/common/mail.svg" alt="">
                    <div class="mail_details">{{ element?.leadMail }}</div>
                  </div>
                  <!-- <div class="mail-item">
                    <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/386081c1700032158825suitcase.png" alt="">
                    <div>{{ (element.companyName | titlecase) || "N/A" }}</div>
                  </div> -->
                </div>
              </div>
            </div>
          </ng-container>
          

        <hr class="section-divider-small">

        <div>
            <div class="input-label">Invite participants by email address</div>
            <textarea class="text-box"
                placeholder="Add email address"
                cols="100"
                rows="4"
                [(ngModel)]="particpantsMails"
          ></textarea>
            <div class="subtext">Use commas (",") to separate email addresses.</div>
        </div>
    </div>
    <div class="button-container">
        <div class="cancel-btn" (click)="cancel()">
            Cancel
        </div>
        <button class="create-btn" (click)="addParticipants()">
            Add Participants
        </button>
    </div>
</section>
