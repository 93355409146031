import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { stat } from 'fs';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
  ) { }


  openSnak(message:any,action:any){
    return this.snackbar.open(
      `${message}`,
      `${action}`,
      {
        duration:1500
      }
    )
  }
  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 1500 });
  }
  openDialog(componentName:any, height:any, width:any, dataModel:any, maxWidth: string = '95vw', maxHeight: string = '80vh') {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: false,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    });
  }

  openFullWidthDialog(componentName:any, height:any, width:any, dataModel:any, maxWidth: string = '100vw', maxHeight: string = '100vh') {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: false,
      maxWidth: maxWidth,
      maxHeight: maxHeight
    });
  }


  openServiceDialog(componentName:any, height:any, width:any, maxWidth:any, dataModel:any, position: any = {right:'0px',top:'0px'}) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth:maxWidth,
      data: dataModel,
      disableClose: false,
      position: position,
    });
  }

  openBottomSheet(componentName: any,data:any){
    return this. _bottomSheet.open(componentName, {
      data:data,

    });
  }

  openDeleteDialog(componentName: any, height: any, width: any, dataModel: any, maxwidth: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
      maxWidth: maxwidth,
    });
  }

  getAllGrades(){
    return this.http.get(
      environment.baseUrl+`crm/master/getAllGradeMasterDetails`
    )
  }

  getAllListCampus(){
    return this.http.get(
      environment.baseUrl+ `crm/campus/getAllCampusDetails`
    )
  }

  getAllAcademicYears(){
    return this.http.get(
      environment.baseUrl+`crm/master/getAllAcademicYearMasterDetails`
    )
  }

  //EXAM MODULE
  getAllScheduledExams(data:any){
    return this.http.put(
      environment.baseUrl+`crm/master/examSchedule/filter`,data
    )
  }

  changeExamStatus(status: any , examId: any  ){
    return this.http.put(
      environment.baseUrl + `crm/master/examSchedule/status?status=${status}&id=${examId}`,{}
    )
  }
  deleteExam(examId: any  ){
    return this.http.delete(
      environment.baseUrl + `crm/master/examSchedule?id=${examId}`,{}
    )
  }
  // for add exam
  addExam(data:any){
    return this.http.post(
      environment.baseUrl+`crm/master/examSchedule`,data
    )
  }
  // for upadate exam
  updateExam(data:any){
    return this.http.put(
      environment.baseUrl+`crm/master/examSchedule`,data
    )
  }

  getSelectedListFields(bId:any,showInErp:any,formName:any){
    return this.http.get(
      environment.baseUrl+ `crm/fields?businessId=${bId}&status=true&showInErp=${showInErp}&isPagination=false&fieldCategory=${formName}`
    )
  }

  activeInactiveFieldsForList(data:any){
    return this.http.put(
      environment.baseUrl+`crm/fields/update-selected?fieldId=${data.fieldId}&fieldCategory=REGISTRATION&isSelected=${data.isSelected}`,data
    )
  }

  //registrations
  createRegistration(data:any){
    return this.http.post(
      environment.baseUrl+`crm/registration/`,data
    )
  }

  getAllRegistrations(data:any){
    return this.http.put(
      environment.baseUrl+ `crm/registration/getAll`,data
    )
  }

  getExamAttendance(data:any){
    return this.http.put(
      environment.baseUrl+ `crm/registration/exam`,data
    )
  }

  getExamResults(data:any,result:any){
    return this.http.put(
      environment.baseUrl+ `crm/registration/exam?isResult=${result}`,data
    )
  }

  getRegistrationById(regId : any,examResult : boolean)
  {
    return this.http.get(
      environment.baseUrl + `crm/registration/${regId}?examData=${examResult}`
    )
  }

  changeExamAttendanceStatus(session:any,status: any , examId: any){
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/update?session=${session}&id=${examId}&attendance=${status}`,{}
    )
  }

  downloadExamAttendanceData(data: any) {
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/excel/download`, data,{ responseType: 'blob' }
    )
  }

  importItem(businessId: any, formData: FormData) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type');
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/excel/upload?businessId=${businessId}`, formData, { headers: headers }
    )
  }
  bulkAttendanceStatusUpdate(data: any) {
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/update/multi`, data
    )
  }

  downloadExamResultData(data: any) {
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/result/excel/download`, data,{ responseType: 'blob' }
    )
  }

  importExamResultItem(businessId: any, formData: FormData) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'application/json');
    headers.delete('Content-Type');
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/result/excel/upload?businessId=${businessId}`, formData, { headers: headers }
    )
  }

  sendCommunication(request:any){
    return this.http.put(
      environment.baseUrl + `crm/registration/communication/send`,request
    )
  }
  sendAdmissionLink(registrationId:any,templateId:any){
    return this.http.put(
      environment.baseUrl + `crm/registration/email?registrationId=${registrationId}&templateId=${templateId}`,{}
    )
  }

  resultAnnouncement(payload)
  {
    return this.http.put
    (
      environment.baseUrl + `crm/registration/exam/result/announce`,payload
    )
  }

  addRemark(payload)
  {
    return this.http.put(
      environment.baseUrl + `crm/registration/exam/result/remark` , payload
    )
  }

  updateResultStatus(id,status)
  {
     return this.http.put(
      environment.baseUrl  + `crm/registration/exam/result?id=${id}&result=${status}`,''
     )

  }

  verifyPaymentStatus(orderId) {
    return this.http.get(
      environment.baseUrl + `crm/registration/manual/registration/payment/status?orderId=${orderId}`
    )
  }


  updateExamResult(payload)
  {
    return this.http.put
    (
      environment.baseUrl + `crm/registration/exam/result/data` , payload
    )
  }


}
