import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class StudentManagementService {
  constructor(
    public dialog: MatDialog,
    private http: HttpClient
  ) { }


  getFieldsForAdmission(fieldsList: any[]) {
    let map = {};

    for(let field of fieldsList) {
      for(let fieldGroup of field.fieldGroup) {
        if(!fieldGroup.fieldStepId)
          continue;

        let stepName = fieldGroup.fieldStepName ? fieldGroup.fieldStepName : 'undefined';
        let groupName = fieldGroup.fieldGroupName;

        if(!map[stepName]) {
          map[stepName] = {};
        }
        if(!map[stepName][groupName]) {
          map[stepName][groupName] = [];
        }
        field['fieldStepId'] = fieldGroup.fieldStepId;
        field['fieldGroupId'] = fieldGroup.fieldGroupId;

        const newField = JSON.parse(JSON.stringify(field));
        map[stepName][groupName].push(newField);
      }
    }

    return map;
  }

  getFieldsForDisplay(fieldsList: any[]) {
    let map = {};

    for(let field of fieldsList) {
        if(!field.fieldStepId)
          continue;

        let stepName = field.fieldStepName ? field.fieldStepName : 'undefined';
        let groupName = field.fieldGroupName;

        if(!map[stepName]) {
          map[stepName] = {};
        }
        if(!map[stepName][groupName]) {
          map[stepName][groupName] = [];
        }
        field['fieldStepId'] = field.fieldStepId;
        field['fieldGroupId'] = field.fieldGroupId;

        map[stepName][groupName].push(field);

    }

    return map;

  }

  createAdmission(payload: any) {
    return this.http.post(
      environment.baseUrl + `crm/registration/admission?registrationId=${payload?.registrationId || ''}`, payload
    )
  }

  createPaymentForAdmission(payload: any) {
    return this.http.post(
      environment.baseUrl + `crm/registration/admission/payment`, payload
    )
  }

  getAdmissionDataById(id) {
    return this.http.get(
      environment.baseUrl + `crm/registration/admission/one?id=${id}`
    )
  }

  getAdmissionList(businessId: any, pageNo: number, size: number) {
    return this.http.get(
      environment.baseUrl + `crm/registration/admission?businessId=${businessId}&pageNo=${pageNo}&size=${size}`
    )
  }

  searchAdmissionList(payload)
  {
    return this.http.put(
      environment.baseUrl + `crm/registration/admission/getAll` , payload
    )
  }

  createRegistration(payload: any) {
    return this.http.post(
      environment.baseUrl + `crm/registration/?enquiryId=${payload?.enquiryId || ''}`, payload
    )
  }

  createRegistrationV2(payload: any) {
    return this.http.post(
      environment.baseUrl + `crm/registration/registration/payment`, payload
    )
  }

  getRegistrationList(payload: any) {
    return this.http.put(
      environment.baseUrl + `crm/registration/getAll`, payload
    )
  }

  inActiveRegistration(id: string) {
    return this.http.delete(
      environment.baseUrl + `crm/registration?id=${id}`
    )
  }

  checkPaymentStatus(orderId: any) {
    return this.http.get(
      environment.baseUrl + `crm/registration/manual/payment/status?orderId=${orderId}`
    )
  }

  getAllDocument(payload: any) {
    return this.http.put(
      environment.baseUrl +  `crm/master/documentType/filter`, payload)
  }

  getAllStudentList(payload)
  {
    return this.http.put(
      environment.baseUrl + `crm/registration/student/filter` , payload
    )
  }

  getSingleStudentData(id)
  {
    return this.http.get(
      environment.baseUrl  + `crm/registration/student/${id}`
    )
  }

  uploadDocument(payload) {
    return this.http.put(
      environment.baseUrl + `crm/registration/student/docs/update`, payload
    )
  }

  createcharactercertificate(payload) {
    return this.http.post(
      environment.baseUrl+`crm/certificates/character`, payload
    )
  }

  listcharactercertificate(payload) {
    return this.http.put(
      environment.baseUrl+`crm/certificates/character/list`,payload
    )
  }



}
