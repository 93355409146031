import { Component, OnInit } from '@angular/core';
import { StorageKeys } from './shared-module-files/simpo.constant';
import { StaffServiceService } from './master-config-components/micro-apps/staff/service/staff-service.service';
import { ActivatedRoute } from '@angular/router';
import { AuthServiceService } from './services/auth-service.service';
import { SubscriptionService } from './services/subscription.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(private staffService: StaffServiceService,
    private subscriptionService: SubscriptionService,
        private activate : ActivatedRoute
  ){}


  subscrictinDetailsSubscription?: Subscription;
  ngOnInit(): void {
    this.getStaffPermission()

    this.subscrictinDetailsSubscription = this.subscriptionService.getSubscriptionDetails().subscribe()

    localStorage.setItem("REQUEST_FROM", "ECOMMERCE");
     this.activate.queryParams.subscribe((qParam)=>{
      const partnerId = qParam["partnerId"]
      if(partnerId)
      {
        localStorage.setItem("partnerId",partnerId);
      }
    })
  }

  ngOnDestroy() {
    this.subscrictinDetailsSubscription?.unsubscribe();
  }

  title = 'beeos-website-generator';

  getStaffPermission(){
    let staffId = localStorage.getItem(StorageKeys.STAFF_ID);
    let businessId = localStorage.getItem("businessId");

    if(staffId && businessId) {
      this.staffService.getStaffPermissionByStaffId(staffId, businessId).subscribe((res: any) => {
        localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data));
      })
    }
  }
}
