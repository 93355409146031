import { Component } from '@angular/core';
import { StudentManagementService } from '../../student-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationService } from '../../../registrations/registration.service';
import { TemplateComponent } from 'src/app/master-config-components/micro-apps/crm/crm-config/enquiry-form-test/template/template.component';
import { CrmServiceService } from 'src/app/master-config-components/micro-apps/crm/service/crm-service.service';

@Component({
  selector: 'app-verify-payment',
  templateUrl: './verify-payment.component.html',
  styleUrls: ['./verify-payment.component.scss']
})
export class VerifyPaymentComponent {

  constructor(private studentManagementService: StudentManagementService,
    private registrationService: RegistrationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private crmService: CrmServiceService
  ) {}

  private API_COUNT: number = 0;
  private MAX_API_COUNT: number = 20;

  paymentType: 'ADMISSION' | 'REGISTRATION' = 'ADMISSION'
  redirect: boolean = true;
  businessId: string = ''
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      if(res.url) {
        this.redirect = false;
      }
      if(res?.type) {
        this.paymentType = res?.type;
      }
      if(res?.bId){
        this.businessId = res?.bId;
      }
      this.getTemplates();
    })

    if(this.router.url.includes('student-management')) {
      this.paymentType = 'ADMISSION';
    } else if(this.router.url.includes('registrations')) {
      this.paymentType = 'REGISTRATION';
    }

    // setTimeout(() => {
      this.paymentType === 'ADMISSION' ? this.verifyPaymentStatus() : this.verifyRegistrationPaymentStatus();
    // }, 2000);

    // setTimeout(() => {
    //   this.paymentStatus = 'SUCCESS'
    // }, 5000)
  }

  paymentStatus: 'ONGOING' | 'SUCCESS' | 'FAILED' = 'ONGOING'

  verifyPaymentStatus() {
    this.API_COUNT++;

    const orderId = localStorage.getItem('admissionOrderId');
    this.studentManagementService.checkPaymentStatus(orderId).subscribe((res: any) => {
      this.paymentStatus = 'SUCCESS';
      localStorage.removeItem('admissionOrderId');

      if(this.redirect) {
        setTimeout(() => {
          this.router.navigate(['/admin/student-management/list-admission'])
        }, 5000)
      }
    }, (err) => {
      if (this.API_COUNT >= this.MAX_API_COUNT) {
        this.paymentStatus = 'FAILED';
        if(this.redirect) {
          setTimeout(() => {
            this.router.navigate(['/admin/student-management/list-admission'])
          }, 5000)
        }
      } else {
        this.verifyPaymentStatus();
      }
    })
  }

  verifyRegistrationPaymentStatus() {
    this.API_COUNT++;
    const orderId = localStorage.getItem('registrationOrderId');
    this.registrationService.verifyPaymentStatus(orderId).subscribe((res: any) => {
      this.paymentStatus = 'SUCCESS';
      localStorage.removeItem('registrationOrderId');
      if(this.redirect) {
        setTimeout(() => {
          this.router.navigate(['/admin/registrations/list-registration'])
        }, 5000)
      }
    }, (err) => {
      if (this.API_COUNT >= this.MAX_API_COUNT) {
        this.paymentStatus = 'FAILED';
        if(this.redirect) {
          setTimeout(() => {
            this.router.navigate(['/admin/registrations/list-registration'])
          }, 5000)
        }
      } else {
        this.verifyRegistrationPaymentStatus();
      }
    })

  }

  templateData: any;
  getTemplates(){
    this.crmService.getActiveTemplate(this.businessId,this.paymentType).subscribe(
      (res:any) =>{
        this.templateData = res.data.data[0].templates;
      },
      (err) =>{}
    )
  }

}
