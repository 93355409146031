import { Component,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DbService } from 'src/app/services/db-service/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CrmServiceService } from '../../../service/crm-service.service';

@Component({
  selector: 'app-create-field-group',
  templateUrl: './create-field-group.component.html',
  styleUrls: ['./create-field-group.component.scss']
})
export class CreateFieldGroupComponent implements OnInit  {

  constructor ( 
    public dailogRef: MatDialogRef<CreateFieldGroupComponent>,
    public dialog: MatDialog,
    private crmService: CrmServiceService,
    private dbService: DbService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
  }

  fieldGroupDetails: any = {
    businessId: this.dbService.getBusinessId(),
    fieldGroupName: "",
}
  createFieldgroup() {
  try {
    // Create Field Group
    this.crmService.createFieldGroup(this.fieldGroupDetails).subscribe({
      next: (value: any) => {
        this.dailogRef.close(value);
        
      },
      error: (err: any) => {
        this.crmService.openSnack(err.error.message, "Close");
      }
    });
  } catch (error) {
    this.snackbar.open('Error occurred while creating field group', 'close', { duration: 5000 });
  } finally {
  }
  }
  

  dismiss(){
    this.dailogRef.close();
  }



}
