import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { forkJoin, map, of, tap } from 'rxjs';
import { StorageKeys } from '../shared-module-files/simpo.constant';
import { MatDialog } from '@angular/material/dialog';
import { PricingDetailsComponent } from '../shared-module-files/components/pricing-details/pricing-details.component';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {

  constructor(private http: HttpClient,
    public dialog: MatDialog
  ) {

  }

  subscriptionDetails: any;
  currentPlanDetails: any;

  getSubscriptionDetails() {
    if (this.subscriptionDetails) {
      return of(this.subscriptionDetails)
    }
    else {
      return this.http.get(environment.baseBusinessUrl + `subscription?countryId=1edf312c-47f4-6b90-a4bd-630e27ba1c08`).pipe(
        tap((data) => {
          this.subscriptionDetails = data
        })
      )
    }
  }

  getCurrentPlanDetails() {
    if (this.currentPlanDetails) {
      return of(this.currentPlanDetails)
    }
    else {
      const businessId = localStorage.getItem('businessId');
      return this.http.get(environment.baseBusinessUrl + `business/preference?businessId=${businessId}`).pipe(
        tap((data) => {
          this.currentPlanDetails = data
        })
      )
    }
  }

  changeCurrentPlanDetails() {
    this.currentPlanDetails = null;
  }

  getNextplanFeatures() {
    return forkJoin({
      subscription: this.getSubscriptionDetails(),
      currentPlan: this.getCurrentPlanDetails()
    }).pipe(
      map((results) => {
        const result = {
          subscription: results.subscription.data,
          currentPlan: results.currentPlan,
        };

        let index = -1;
        for(let i = 0;i<result.subscription.length;i++) {
          let subscription = result.subscription[i].plan;
          if(subscription.id === result.currentPlan.planId) {
            index = i;
            break;
          }
        }

        if(index != -1 && index < result.subscription.length - 1)
          return result.subscription[index + 1];

        return result.subscription[result.subscription.length - 1];
      })
    );
  }

  updatePlan(moduleName: string, subModuleName: string) {
    let businessId = localStorage.getItem('businessId');
    return this.http.patch(
      environment.baseBusinessUrl + `business/update/preference?businessId=${businessId}&moduleName=${moduleName}&submoduleName=${subModuleName}&reduceLimitBy=1`, ''
    )
  }

  openDialog(componentName: any, height: any, width: any, data: any) {
    return this.dialog.open(componentName, {
      height: height,
      width: width,
      maxWidth : "100vw",
      data: data
    });
  }

  openPricingModal() {
    if(localStorage.getItem('planId') && localStorage.getItem('planId') != null && localStorage.getItem('planId') != 'undefined') {
      this.openDialog(PricingDetailsComponent, '100vh', '100vw', {});
      localStorage.removeItem('planId');
    }
  }

  getDetailsByBusinessId(businessId: any) {
    return this.http.get(environment.baseBusinessUrl + `business/${businessId}`);
  }

}
