<section class="main-section">
    <div class="header">
      <p class="heading">Divider</p>
      <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
    </div>

    <div class="svg-main-section">
      <ng-container *ngFor="let svg of Object.keys(dividerList)">
        <div  [ngClass]="current == svg ? 'active-1' : 'svg-icon'" (click)="changeDivider(svg)">
          <div class="selected" *ngIf="current != svg"><div class="select">Select</div></div>
          <div class="selected" *ngIf="current == svg"><div class="select-1">Selected<mat-icon class="done-icon">done</mat-icon></div></div>
          <div class="custom-shape-divider-bottom" [style.transform]="dividerList[svg].rotate ? 'rotate(180deg)' : ''">
            <svg [attr.fill]="current == svg ? '#0496FF' : '#9CA3AF'" [style.transform]="dividerList[svg].rotateY ? 'rotateY(180deg)' : ''" data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path [attr.d]="dividerList[svg].svg" class="shape-fill"></path>
            </svg>
          </div>
          <div [ngClass]="current == svg ? 'active' : 'empty-div'"></div>
        </div>
      </ng-container>
    </div>
</section>
