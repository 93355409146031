import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WebisiteService } from '../../webisite.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { text } from 'stream/consumers';

@Component({
  selector: 'app-link-domain',
  templateUrl: './link-domain.component.html',
  styleUrls: ['./link-domain.component.scss']
})
export class LinkDomainComponent {

  constructor(
    public dialogRef: MatDialogRef<LinkDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _websiteService : WebisiteService,
    private _snackbar : MatSnackBar,
  ){
  }

  linkingDomain :any = '';
  businessDetails : any ;
  domainData:any;
  ngOnInit(){
    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails){
      this.businessDetails = JSON.parse(bDetails);
    }
  }

  close(){
    this.dialogRef.close({data:null});
  }

  submitLoader : boolean = false;


  steps : any = [
    {subhead : "1. Access Domain Settings:",text:" Log in to your domain registrar's account where you purchased the domain. Locate the domain management or settings section." , subList :""},
    {subhead : "2. DNS Settings",text:" Look for the DNS settings or DNS management section. You'll be modifying DNS records to point to Simpo.ai's servers.",subList : ""},
    {subhead : "3. Create A Record",text:"",subList:[{sublistHead : "Hostname:",subListText:"@"},{sublistHead : "Type:",subListText:"A"},{sublistHead : "Value:",subListText:"52.172.153.86"}]},
    {subhead : "4. Save Changes",text:" Once you’ve create a CNAME record next Enter Registered domain and copy Hostname and  , click on submit and verify button below.",subList : ""},
  ]
  ct : number = 0
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.ct++;
    this._snackbar.open("Copied",'Close')
  }

  submitVerify(){
    this.submitLoader = true;
    let data = {
      businessId : this.businessDetails.id,
      domainUrl : (this.linkingDomain).toLowerCase(),
      force:false
    }

    this._websiteService.gettinEntriToken(data).subscribe(
      (res:any) => {
        this.submitLoader = false;
        this.dialogRef.close({data:res.data,domainUrl:this.linkingDomain,businessId:data.businessId})
      },
      (err) => {
        this.submitLoader = false;
        this._snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }


}
