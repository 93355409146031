import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DbService } from './db-service/db.service';
import { BusinessServiceService } from '../master-config-components/micro-apps/business/services/business-service.service';
import { SubscriptionService } from './subscription.service';
import { StorageKeys } from '../shared-module-files/simpo.constant';
import { tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isLoggedIn() {
    return !localStorage.getItem('businessId');
  }

  //here temporarly setting staffid for login

  constructor(
    private _router: Router,
    private _dbService: DbService,
    private router: ActivatedRoute,
    private businessService: BusinessServiceService,
    private subscriptionService: SubscriptionService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot) {
    const businessId = route.queryParams['businessId'];
    const staffId = route.queryParams['staffId'];

    if (businessId && staffId) {
      localStorage.setItem("businessId", businessId)
      localStorage.setItem("staffId", staffId)
      return this.businessService.getDetailsByBusinessId(businessId, staffId).pipe(
        tap( (res: any) => {
          window.localStorage.setItem('bDetails', JSON.stringify(res.data.business));
          localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
          localStorage.setItem(StorageKeys.PERMISSIONS, JSON.stringify(res.data.staffPermissions))
          localStorage.setItem(StorageKeys.SUBINDUSTRYID, res.data?.business?.subIndustryId);
          localStorage.setItem("mobileLogin",JSON.stringify(true))
          this._dbService.setBusinessId(res.data.business.id);
          window.localStorage.setItem('type', 'admin');
          this.subscriptionService.changeCurrentPlanDetails();
          this._router.navigateByUrl(state.url.split('?')[0])
        })
      );
      // console.log(state.url)
      // this._router.navigateByUrl(state.url)
    } else {
      if (this._dbService.getBusinessId()) {
        return true;
      }
      this._router.navigate(['']);
      return false;
    }
  }

}
