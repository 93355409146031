import { HttpClient } from '@angular/common/http';
import { AbsoluteSourceSpan } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnyARecord } from 'dns';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceServiceService {

  constructor(
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private http: HttpClient,
    private snackbar: MatSnackBar,
  ) { }

  openDialog(componentName: any, height: any, width: any, maxWidth: any, dataModel: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth: maxWidth,
      data: dataModel,
      disableClose: true,
      position: { right: '0px', top: '0px' },
    });
  }
  openServiceDialog(componentName:any, height:any, width:any, maxWidth:any, dataModel:any, position: any = {right:'0px',top:'0px'}) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      maxWidth:maxWidth,
      data: dataModel,
      disableClose: true,
      position: position,
    });
  }
  openSocialBrand(componentName: any, height: any, width: any, data: any, maxWidth: string = '100vw', maxHeight: string = '100vh') {
    return this.dialog.open(componentName, {
      height: height,
      width: width,
      data: data,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
    });
  }

  openSocialBrandPopup(componentName: any, height: any, width: any, data: any, panelClass: string, position?: any) {
    return this.dialog.open(componentName, {
      height: height,
      width: width,
      data: data,
      panelClass: panelClass
    });
  }

  openBottomSheet(componentName: any, data: any) {
    return this._bottomSheet.open(componentName, {
      data: data,

    });
  }
  serachBusinessContact(businessId: any, pageNo: any, size: any, text: any) {
    return this.http.get(
      environment.baseUrl + `crm/leads?businessId=${businessId}&size=${size}&page=${pageNo}&text=${text}`
    )
  }

  searchBusinessInvoiceItems(businessId: any, pageNo: any, size: any, text: any) {
    return this.http.get(
      environment.baseUrl + `business/invoice/invoiceItems?size=${size}&page=${pageNo}&businessId=${businessId}`
    )
  }

  searchBusinessInvoiceItemPaginated(data) {
    return this.http.put(
      environment.baseUrl + `business/invoice/invoiceItems/search`, data
    )
  }

  createInvoice(data: any) {
    return this.http.post(
      environment.baseUrl + `business/invoice/create`, data
    )
  }


  openSnack(message: any, action: any) {
    this.snackbar.open(message, action, { duration: 5000 });
  }


  getInvoiceData(pageNo: any, size: any, businessId) {
    return this.http.get(
      environment.baseUrl + `business/invoice?size=${size}&page=${pageNo}&businessId=${businessId}`
    )
  }

  getInvoiceById(invoiceId: any) {
    return this.http.get(
      environment.baseUrl + `business/invoice/${invoiceId}`
    )
  }

  getInvoiceDashboard(businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/invoice/dashboard?businessId=${businessId}`
    )
  }

  editInvoiceDashboard(data) {
    return this.http.put(
      environment.baseUrl + `business/invoice/update`, data
    )
  }

  updateInvoiceStatus(data: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/updateStatus`, data
    )
  }

  markAsRead(invoiceId: any, data: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/updatePaymentDetails?id=${invoiceId}`, data
    )
  }


  filterInvoice(data: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/filter`, data
    )
  }

  downloadInvoice(invoiceId: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/download?invoiceId=${invoiceId}`, {}
    )
  }
  //, {},{ observe: 'response', responseType: 'blob' }

  getInvoiceListDashboard(businessId: any, startDate: any, endDate: any) {
    return this.http.get(
      environment.baseUrl + `business/invoice/dashboard?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}`
    )
  }


  getInvoiceItems(pageNo: any, size: any, businessId: any) {
    return this.http.get(
      environment.baseUrl + `business/invoice/invoiceItems?size=${size}&page=${pageNo}&businessId=${businessId}`
    )
  }

  deleteInvoiceItems(invoiceId: any) {
    return this.http.delete(
      environment.baseUrl + `business/invoice/delete?id=${invoiceId}`
    )
  }

  sendReceipt(data: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/sendReceipt`, data
    )
  }


  sendRemainder(data: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/sendRemainder`, data
    )
  }

  deleteInvoiceItem(itemId: any) {
    return this.http.delete(
      environment.baseUrl + `business/invoice/delete/invoiceItem?itemId=${itemId}`
    )
  }

  createInvoiceItem(item: any) {
    return this.http.post(
      environment.baseUrl + `business/invoice/invoiceItem`, item
    )
  }

  editInvoiceItem(item: any) {
    return this.http.put(
      environment.baseUrl + `business/invoice/invoiceItem`, item
    )
  }
  convertCurrencySymbol(currencySymbol: any): string {
    if (currencySymbol == null) return "₹";
    const convertedSymbol = String.fromCharCode(parseInt(currencySymbol.substring(2), 10));
    return convertedSymbol;
  }

  checkInvoiceCount(businessId) {
    return this.http.get(
      environment.baseUrl + `business/business/credits?businessId=${businessId}&businessCredits=INVOICE`
    )
  }

  updateInvoiceAccount(data) {
    return this.http.put(
      environment.baseUrl + `business/invoice/invoiceItem`, data
    )
  }



  // new invoice api's

  // invoice listing api

  getInvoiceList(businessId: any, invoiceType: any, pageNo:number, size:number) {
    return this.http.get(
      environment.baseUrl + `ecommerce/invoice/?businessId=${businessId}&invoiceType=${invoiceType}&pageNo=${pageNo}&size=${size} `
    )
  }

  createInvoicePrefix(prefixName: any, businessId: any, prefixFor: any, isDefault: boolean,) {
    return this.http.post(
      environment.baseUrl + `ecommerce/invoice/invoice/prefix/?businessId=${businessId}&prefix=${prefixName}&prefixFor=${prefixFor}&isDefault=${isDefault}`, {}
    )
  }

  getInvoicePrefix(businessId: any, prefixFor: any) {
    return this.http.get(
      environment.baseUrl + `ecommerce/invoice/businessId/prefix/?businessId=${businessId}&prefixFor=${prefixFor}`
    )
  }

  V2createInvoice(data: any, invoiceType: any) {
    return this.http.post(
      environment.baseUrl + `ecommerce/invoice/?invoiceType=${invoiceType}`, data
    )
  }

  editInvoice(invoice: any, invoiceType: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/invoice//?invoiceType=${invoiceType}`, invoice
    )
  }

  v2getInvoiceById(invoiceId: any, invoiceType: any) {
    console.log("hitting api "+ this.http.get(
      environment.baseUrl + `ecommerce/invoice/${invoiceId}?invoiceType=${invoiceType}`
    ))
    return this.http.get(
      environment.baseUrl + `ecommerce/invoice/${invoiceId}?invoiceType=${invoiceType}`
    )
  }
  v2InvoiceFilters(data: any, invoiceType: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/invoice/filter?invoiceType=${invoiceType}&pageNo=0&size=10`, data
    )
  }
  getTaxMaster(taxType?: any) {
    if (taxType) {
      return this.http.get(
        environment.baseUrl + `admin/tax/masters/?taxType=${taxType}`
      )
    }
    else {
      return this.http.get(
        environment.baseUrl + `admin/tax/masters/`
      )
    }
  }


  changePrefixDefaulStatus(prefixId: any, prefixFor: any, status: boolean) {
    return this.http.get(
      environment.baseUrl + `ecommerce/invoice/prefix/default?prefixId=${prefixId}&prefixFor=${prefixFor}&isDefault=${status}`
    )
  }

  getAllSignatureByBusinessId(businessId: any) {
    return this.http.get(
      environment.baseUrl + `ecommerce/signature/?businessId=${businessId}`
    )
  }

  createSignature(request: any) {
    return this.http.post(
      environment.baseUrl + `ecommerce/signature/`, request
    )
  }

  getCategories(request: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/inventory/get/category/`, request
    )
  }

  searchProduct(request: any) {
    if(!request.request)
      request.request = 'BUSINESS';

    return this.http.put(
      environment.baseUrl + `ecommerce/product/item/search`, request
    )
  }
  v2recordPayment(data: any, invoiceType: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/invoice/payment?invoiceType=${invoiceType}`, data
    )
  }
  addBankDetails(data: any) {
    return this.http.post(
      environment.baseUrl + `ecommerce/BankDetail/`, data
    )
  }
  getBankDetails(businessId: any) {
    return this.http.get(
      environment.baseUrl + `ecommerce/BankDetail/?businessId=${businessId}`
    )
  }
  deleteBankDetails(bankDetailId: any) {
    return this.http.delete(
      environment.baseUrl + `ecommerce/BankDetail/?id=${bankDetailId}`
    )
  }
  v2invoiceStatusUpdate(data) {
    return this.http.put(
      environment.baseUrl + `ecommerce/invoice/update/status?invoiceId=${data.invoiceId}&invoiceType=${data.invoiceType}&status=${data.status}&remark=${data.remark}`, data
    )
  }

  v2InvoiceSearch(searchRequest: any, pageNo: any, size: any, businessId: any,invoiceType:any,invoiceStatus : any) {
    if(invoiceStatus == '')
    {
      return this.http.get(
        environment.baseUrl + `ecommerce/invoice/search?searchRequest=${searchRequest}&pageNo=${pageNo}&size=${size}&businessId=${businessId}&invoiceType=${invoiceType}`
      )
    }
    else
    {
    return this.http.get(
      environment.baseUrl + `ecommerce/invoice/search?searchRequest=${searchRequest}&pageNo=${pageNo}&size=${size}&businessId=${businessId}&invoiceType=${invoiceType}&invoiceStatus=${invoiceStatus}`
    )
  }
  }
  v2SendEmail(invoiceId: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/invoice/reminder/mail/?invoiceId=${invoiceId}`, {}
    )
  }
  getAllTaxes() {
    return this.http.get(
      environment.baseUrl + `admin/tax/masters/?taxType=GST`
    )
  }
  getAllHSNvalues(searchRequest) {
    return this.http.get(
      environment.baseUrl + `admin/hsn/masters/search?searchRequest=${searchRequest}`
    )
  }

  getAllPrimaryUnits() {
    return this.http.get(
      environment.baseUrl + `admin/unit/masters/`
    )
  }

  createItem(data) {
    return this.http.post(
      environment.baseUrl + `ecommerce/product/item`, data
    )
  }

  //get ledger api

  getLedger(customerId, businessId , startDate , endDate) {
 
    let url = ""
    if(customerId.length > 0)
      url = url + `customerId=${customerId}&`
    if(businessId.length > 0)
      url = url + `businessId=${businessId}&`
    if(startDate)
      url = url + `sDate=${startDate}`
    if(endDate)
      url = url + `&eDate=${endDate}`
    return this.http.get(
      environment.baseUrl + `ecommerce/ledger/?${url}`
    )
  }

  getTransaction(payload)
  {
    return this.http.put(
     environment.baseUrl + `ecommerce/report/transactions`, payload
    )
  }

  recordeLedger(data) {
    return this.http.put(
      environment.baseUrl + `ecommerce/ledger/payment`, data
    )
  }

  downloadExcel(startDate, endDate, ledgerId) {
    return this.http.get(
      environment.baseUrl + `ecommerce/ledger/export/ledger?startDate=${startDate}&endDate=${endDate}&ledgerId=${ledgerId}`, { responseType: 'blob' }
    )
  }
  getCustomerList(businessId:any,pageNo:any,size:any,leadType:any){
    return this.http.get(
      environment.baseUrl+`crm/lead/v2?businessId=${businessId}&size=${size}&pageNo=${pageNo}&leadType=${leadType}`
    )
  }

  updateProductItem(data: any) {
    return this.http.put(
      environment.baseUrl + `ecommerce/product/update`, data
    )
  }
  // v3 add section api
  getAllComponents()
  {
    return this.http.get(
      environment.serverIp + `v3/get/all/components`
    )
  }

  sendInvoiceNotification(requestBody: any, isWhatsapp: boolean) {
    return this.http.post(
      environment.baseUrl + `ecommerce/invoice/notification?isWhatsapp=${isWhatsapp}`, requestBody
    );
  }

  removeUnderscore(type : any)
  {
   let s = type.split("_").join(' ')
   return s
  }
  
}
